/* NimbusLogo.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50vh;
    right: 0vh;
    background-color: #333; /* Dark grey background color */
    padding: 10px; /* Optional padding for better visual on smaller devices */
    box-sizing: border-box; /* Ensure padding is included in height calculation */
  }
  .textp {
    position: absolute;
    font-size: x-small;
  }
  .insidet {
    color: rgb(116, 119, 118);
    position: 'flex';
    align-items: center;
    padding: 10px;
    font-size: small;
    position: relative;
    min-height: 300px;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Responsive design adjustments */
  @media (max-width: 600px) {
    .container {
      padding: 10px; /* Adjust padding for smaller devices */
    }
  }

.textcss {
    display: flex;
    font-size: small;
    justify-content: center;
    align-items: center;
    font: small-caps;
    background-color: #c4bebe; /* Dark grey background color */
    padding: 20px; /* Optional padding for better visual on smaller devices */
    box-sizing: border-box; /* Ensure padding is included in height calculation */
}
  