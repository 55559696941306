.footer {
    position: flex;
    bottom: 100;
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px 0;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    font-family: 'Roboto', sans-serif;
  }
  