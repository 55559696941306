body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #333;
  color: #333;
}
.App-footer{
  position:fixed;
  box-shadow: 0 30px 40px rgba(0,0,0,.1);
}
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-header .logo {
  font-size: 2.5rem;
  animation: fadeIn 2s ease-in-out;
}

.App-header nav ul {
  list-style: none;
  padding: 0;
}

.App-header nav ul li {
  display: inline;
  margin: 0 15px;
}

.App-header nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.intro, .features, .development {
  padding: 40px 20px;
  background: #fff;
  margin: 20px auto;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.intro {
  animation: slideInLeft 1s ease-out;
}

.features {
  animation: slideInRight 1s ease-out;
}

.development {
  background: #ffd700;
  animation: fadeInUp 1.5s ease-out;
}

.development h2 {
  color: #d35400;
}

.development a {
  color: #d35400;
  text-decoration: none;
}

.development a:hover {
  text-decoration: underline;
}

.App-footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
